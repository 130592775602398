import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography, CircularProgress, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface FormAccordionProps {
    title: string;
    isLoading: boolean;
    children: React.ReactNode;
}

const FormAccordion: React.FC<FormAccordionProps> = ({ title, isLoading, children }) => {
    return (
        <Accordion defaultExpanded>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="additional-content"
                id="additional-header"
            >
                <Typography variant="h6">{title}</Typography>
            </AccordionSummary>
            <AccordionDetails
                sx={{
                    position: 'relative',
                }}
            >
                <Box
                    sx={{
                        display: isLoading ? 'flex' : 'none',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        zIndex: 2,
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <CircularProgress />
                </Box>
                {children}
            </AccordionDetails>
        </Accordion>
    );
};

export default FormAccordion;

import React from "react";
import {TextField} from "@mui/material";

interface MultilineTextFieldProps {
    id: string;
    label: string;
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const MultilineTextField: React.FC<MultilineTextFieldProps> = ({
                                                                   id,
                                                                   label,
                                                                   value,
                                                                   onChange,
                                                               }) => {
    // Berechnen der Anzahl der Zeilenumbrüche (\n) im Text
    const lineBreakCount = (value.match(/\n/g) || []).length;

    return (
        <TextField
            fullWidth
            id={id}
            multiline
            minRows={lineBreakCount + 1} // Plus eins für die erste Zeile
            value={value}
            variant="outlined"
            label={label}
            onChange={onChange}
        />
    );
};

export default MultilineTextField;

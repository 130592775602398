// IndexPage.tsx
import React, { useRef, useState, useEffect } from 'react';
import { Grid } from '@mui/material';
// ... other imports
import {Toaster} from "react-hot-toast";
import PatientDataForm from "./sections/data-form/patient-data-form";
import PdfFileUpload from "./sections/file-upload/pdf-file-upload";
import WidthContext from "../../context/width-context";

const IndexPage = () => {
    const gridRef = useRef<HTMLDivElement>(null);
    const [width, setWidth] = useState<number | null>(null);

    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            for (let entry of entries) {
                setWidth(entry.contentRect.width);
            }
        });

        if (gridRef.current) {
            resizeObserver.observe(gridRef.current);
        }

        return () => {
            if (gridRef.current) {
                resizeObserver.unobserve(gridRef.current);
            }
        };
    }, []);

    return (
        <WidthContext.Provider value={width}>
            <Toaster/>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <PatientDataForm/>
                </Grid>
                <Grid item xs={12} md={6} ref={gridRef}>
                    <PdfFileUpload/>
                </Grid>
            </Grid>
        </WidthContext.Provider>
    );
};

export default IndexPage;

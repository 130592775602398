import React, {useEffect, useState} from "react";
import {Accordion, AccordionDetails, AccordionSummary, Grid, TextField, Typography} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {pdfProcessingService} from "../../../../../services/PdfProcessingService";
import {PatientDataMapper} from "../../../../../services/PatientDataMapperService";
import {Referrer} from "../../../../../types/types";
import FormAccordion from "../../../../../layouts/form-accordion-layout";
import MultilineTextField from "../../../../../components/MultilineTextField";


const ReferrerForm = () => {
    const [formData, setFormData] = useState<Referrer>(PatientDataMapper.generateEmptyReferrer);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        const subscription = pdfProcessingService.referrerResult$.subscribe(result => {
            if (result) {
                setFormData(result.referrer);
                setIsLoading(result.isLoading)
            } else {
                setIsLoading(false)
                const initData = PatientDataMapper.generateEmptyReferrer()
                setFormData(initData)
            }
        });

        // Cleanup-Funktion, wenn die Komponente unmounted
        return () => subscription.unsubscribe();
    }, []);

    const referrerFormFields = <Grid container spacing={2}>
        {
            Object.entries(formData.personalDetails).map(([key, {
                labelName,
                value
            }]) => (
                <Grid item xs={12} sm={4} key={key}>
                    <MultilineTextField
                        id={"doc-" + key}
                        label={labelName}
                        value={value}
                        onChange={(e) =>
                            setFormData({
                                ...formData,
                                personalDetails: {
                                    ...formData.personalDetails,
                                    [key]: {
                                        labelName: labelName,
                                        value: e.target.value,
                                    },
                                },
                            })
                        }
                    />
                </Grid>
            ))
        }
    </Grid>

    return (
        <Grid item xs={12}>
            <FormAccordion title="Zuweiser" isLoading={isLoading}>
                {referrerFormFields}
            </FormAccordion>
        </Grid>
    );
};
export default ReferrerForm;
import React, {useEffect, useState} from "react";
import {Box, Divider, Grid} from "@mui/material";
import {pdfProcessingService} from "../../../../../services/PdfProcessingService";
import {PatientDataMapper} from "../../../../../services/PatientDataMapperService";
import {Diagnosis} from "../../../../../types/types";
import FormAccordion from "../../../../../layouts/form-accordion-layout";
import MultilineTextField from "../../../../../components/MultilineTextField";
import Stack from "@mui/material/Stack";

const DiagnosisForm = () => {
    const [formData, setFormData] = useState<Diagnosis>(PatientDataMapper.generateEmptyDiagnosis);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        const subscription = pdfProcessingService.diagnosisResult$.subscribe(result => {
            if (result) {
                setFormData(result.diagnosis);
                setIsLoading(result.isLoading)
            } else {
                setIsLoading(false)
                const initData: Diagnosis = PatientDataMapper.generateEmptyDiagnosis()
                setFormData(initData)
            }
        });

        // Cleanup-Funktion, wenn die Komponente unmounted
        return () => subscription.unsubscribe();
    }, []);


    const updateAcuteOrChronicDiagnosis = (index: number, field: 'acuteDiagnosisName' | 'chronicDiagnosisName' | 'acuteDiagnosisIcd10Code' | 'chronicDiagnosisIcd10Code', newValue: string) => {
        const updatedFormData = {...formData};

        if (field === 'acuteDiagnosisName') {
            updatedFormData.acuteDiagnosis[index].name.value = newValue;
        } else if (field === 'chronicDiagnosisName') {
            updatedFormData.chronicDiagnosis[index].name.value = newValue;
        } else if (field === 'acuteDiagnosisIcd10Code') {
            updatedFormData.acuteDiagnosis[index].icd10Code.value = newValue;
        } else if (field === 'chronicDiagnosisIcd10Code') {
            updatedFormData.chronicDiagnosis[index].icd10Code.value = newValue;
        }
        setFormData(updatedFormData);
    };

    const formFields = (
        <Stack>
            <Grid container spacing={2}> {/* Adjust '2' and '3' based on your spacing needs */}
                {
                    formData.acuteDiagnosis.map((acuteDiagnosis, index) => (
                        <React.Fragment key={index}>
                            <Grid item xs={12} sm={8}>
                                <MultilineTextField
                                    id={"acuteDiagnosis-name-" + (index + 1)}
                                    label={acuteDiagnosis.name.labelName + " " + (index + 1)}
                                    value={acuteDiagnosis.name.value}
                                    onChange={(e) => updateAcuteOrChronicDiagnosis(index, 'acuteDiagnosisName', e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <MultilineTextField
                                    id={"acuteDiagnosis-icd10Code-" + (index + 1)}
                                    label={acuteDiagnosis.icd10Code.labelName + " " + (index + 1)}
                                    value={acuteDiagnosis.icd10Code.value}
                                    onChange={(e) => updateAcuteOrChronicDiagnosis(index, 'acuteDiagnosisIcd10Code', e.target.value)}
                                />
                            </Grid>
                        </React.Fragment>
                    ))
                }
            </Grid>
            <Box sx={{height: 10}}/>
            <Divider/>
            <Box sx={{height: 10}}/>
            <Grid container spacing={2}>
                {
                    formData.chronicDiagnosis.map((chronicDiagnosis, index) => (
                        <React.Fragment key={index}>
                            <Grid item xs={12} sm={8}>
                                <MultilineTextField
                                    id={"chronicDiagnosis-name-" + (index + 1)}
                                    label={chronicDiagnosis.name.labelName + " " + (index + 1)}
                                    value={chronicDiagnosis.name.value}
                                    onChange={(e) => updateAcuteOrChronicDiagnosis(index, 'chronicDiagnosisName', e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <MultilineTextField
                                    id={"chronicDiagnosis-icd10Code-" + (index + 1)}
                                    label={chronicDiagnosis.icd10Code.labelName + " " + (index + 1)}
                                    value={chronicDiagnosis.icd10Code.value}
                                    onChange={(e) => updateAcuteOrChronicDiagnosis(index, 'chronicDiagnosisIcd10Code', e.target.value)}
                                />
                            </Grid>
                        </React.Fragment>
                    ))
                }
            </Grid>
        </Stack>
    )

    return (
        <Grid item xs={12}>
            <FormAccordion title="Diagnose" isLoading={isLoading}>
                {formFields}
            </FormAccordion>
        </Grid>
    );

};
export default DiagnosisForm;
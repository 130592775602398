import * as Yup from 'yup';
import {useFormik} from 'formik';
import {MuiOtpInput} from 'mui-one-time-password-input';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import {supabase} from "../../db/supabaseClient";
import {paths} from "../../paths";
import {RouterLink} from "../../components/RouterLink";
import {useMounted} from "../../hooks/useMounted";
import {useRouter} from "../../hooks/useRouter";
import {useSearchParams} from "../../hooks/use-search-params";

interface Values {
    code: string;
    email: string;
    password: string;
    passwordConfirm: string;
    submit: null;
}

const getInitialValues = (username?: string): Values => ({
    code: '',
    email: username || '',
    password: '',
    passwordConfirm: '',
    submit: null
});

const validationSchema = Yup.object({
    code: Yup
        .string()
        .min(6)
        .max(6)
        .required('Code is required'),
    email: Yup
        .string()
        .email('Must be a valid email')
        .max(255)
        .required('Email is required'),
    password: Yup
        .string()
        .min(7, 'Must be at least 7 characters')
        .max(255)
        .required('Required'),
    passwordConfirm: Yup
        .string()
        .oneOf([Yup.ref('password')], 'Passwords must match')
        .required('Required')
});

const ResetPassword = () => {
    const isMounted = useMounted();
    const router = useRouter();
    const searchParams = useSearchParams();
    const username = searchParams.get('username') || undefined;
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: getInitialValues(username),
        validationSchema,
        onSubmit: async (values, helpers): Promise<void> => {

            try {
                const {error: otpError} = await supabase.auth.verifyOtp({
                    token: values.code,
                    email: values.email,
                    type: "email"
                });

                if (otpError) {
                    throw otpError;
                }

                const {error} = await supabase.auth.updateUser({password: values.password});

                if (error) {
                    throw error;
                }

                if (isMounted()) {
                    const searchParams = new URLSearchParams({username: values.email}).toString();
                    const href = paths.auth.login + `?${searchParams}`;
                    router.push(href);
                }
            } catch (err) {
                console.error(err);

                if (isMounted()) {
                    helpers.setStatus({success: false});
                    // @ts-ignore
                    helpers.setErrors({submit: err.message});
                    helpers.setSubmitting(false);
                }
            }
        }
    });

    //usePageView();

    return (
        <>
            <div>
                <Card elevation={16}>
                    <CardHeader
                        sx={{pb: 0}}
                        title="Reset Password"
                    />
                    <CardContent>
                        <form
                            noValidate
                            onSubmit={formik.handleSubmit}
                        >
                            <Stack spacing={3}>
                                {
                                    username
                                        ? (
                                            <TextField
                                                disabled
                                                fullWidth
                                                label="Email"
                                                value={username}
                                            />
                                        )
                                        : (
                                            <TextField
                                                autoFocus
                                                error={!!(formik.touched.email && formik.errors.email)}
                                                fullWidth
                                                helperText={formik.touched.email && formik.errors.email}
                                                label="Email Address"
                                                name="email"
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                                type="email"
                                                value={formik.values.email}
                                            />
                                        )
                                }
                                <FormControl error={!!(formik.touched.code && formik.errors.code)}>
                                    <FormLabel
                                        sx={{
                                            display: 'block',
                                            mb: 2
                                        }}
                                    >
                                        Verification code
                                    </FormLabel>
                                    <MuiOtpInput
                                        length={6}
                                        onBlur={() => formik.handleBlur('code')}
                                        onChange={(value: any) => formik.setFieldValue('code', value)}
                                        onFocus={() => formik.setFieldTouched('code')}
                                        sx={{
                                            '& .MuiFilledInput-input': {
                                                p: '14px'
                                            }
                                        }}
                                        value={formik.values.code}
                                    />
                                    {!!(formik.touched.code && formik.errors.code) && (
                                        <FormHelperText>
                                            {formik.errors.code}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                                <TextField
                                    error={!!(formik.touched.password && formik.errors.password)}
                                    fullWidth
                                    helperText={formik.touched.password && formik.errors.password}
                                    label="Password"
                                    name="password"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    type="password"
                                    value={formik.values.password}
                                />
                                <TextField
                                    error={!!(formik.touched.passwordConfirm && formik.errors.passwordConfirm)}
                                    fullWidth
                                    helperText={formik.touched.passwordConfirm && formik.errors.passwordConfirm}
                                    label="Password Confirmation"
                                    name="passwordConfirm"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    type="password"
                                    value={formik.values.passwordConfirm}
                                />
                            </Stack>
                            {formik.errors.submit && (
                                <FormHelperText
                                    error
                                    sx={{mt: 3}}
                                >
                                    {formik.errors.submit as string}
                                </FormHelperText>
                            )}
                            <Button
                                disabled={formik.isSubmitting}
                                fullWidth
                                size="large"
                                sx={{mt: 3}}
                                type="submit"
                                variant="contained"
                            >
                                Reset Password
                            </Button>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    mt: 3
                                }}
                            >
                                <Link
                                    component={RouterLink}
                                    href={paths.auth.forgotPassword}
                                    underline="hover"
                                    variant="subtitle2"
                                >
                                    Did you not receive the code?
                                </Link>
                            </Box>
                        </form>
                    </CardContent>
                </Card>
            </div>
        </>
    );
};

export default ResetPassword;

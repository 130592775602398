// login.tsx
import React from 'react';
import * as Yup from 'yup';
import {supabase} from "../../db/supabaseClient";
import {Box, Button, Card, CardContent, CardHeader, FormHelperText, Link, Stack, TextField} from "@mui/material";
import {useFormik} from "formik";
import {useMounted} from "../../hooks/useMounted";
import {useRouter} from "../../hooks/useRouter";
import {RouterLink} from "../../components/RouterLink";
import {paths} from "../../paths";
import {Layout} from "../../layouts/auth-layout";

interface Values {
    email: string;
    password: string;
    submit: null;
}

const initialValues: Values = {
    email: 'demo@devias.io',
    password: 'Password123!',
    submit: null
};

const validationSchema = Yup.object({
    email: Yup
        .string()
        .email('Es muss eine gültige E-Mail Adresse sein')
        .max(255)
        .required('Email ist obligatorisch'),
    password: Yup
        .string()
        .max(255)
        .required('Passwort ist obligatorisch')
});

const Login: React.FC = () => {
    const isMounted = useMounted();
    const router = useRouter();
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, helpers): Promise<void> => {
            try {
                const {error} = await supabase.auth.signInWithPassword({
                    email: values.email,
                    password: values.password,
                });

                if (error) {
                    console.error('Login error', error.message);
                    throw new Error(error.message);
                } else {
                    console.log('Success! You\'re logged in.');
                    if (isMounted()) {
                        window.location.href = '/';
                    }
                }

                if (isMounted()) {
                    window.location.href = '/';
                }
            } catch (err) {
                const error = err as Error; // Typ-Assertion
                console.error(err);

                if (isMounted()) {
                    helpers.setStatus({success: false});
                    helpers.setErrors({submit: error.message});
                    helpers.setSubmitting(false);
                }
            }
        }
    });

    return (
        <>
            <Layout>
                <Card elevation={16}>
                    <CardHeader
                        sx={{
                            textAlign: 'center'
                        }}
                        title="Login"
                    />
                    <CardContent>
                        <form
                            noValidate
                            onSubmit={formik.handleSubmit}
                        >
                            <Stack spacing={3}>
                                <TextField
                                    autoFocus
                                    error={!!(formik.touched.email && formik.errors.email)}
                                    fullWidth
                                    helperText={formik.touched.email && formik.errors.email}
                                    label="Email"
                                    name="email"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    type="email"
                                />
                                <TextField
                                    error={!!(formik.touched.password && formik.errors.password)}
                                    fullWidth
                                    helperText={formik.touched.password && formik.errors.password}
                                    label="Passwort"
                                    name="password"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    type="password"
                                />
                            </Stack>
                            {formik.errors.submit && (
                                <FormHelperText
                                    error
                                    sx={{mt: 3}}
                                >
                                    Ups, das Login hat nicht geklappt!
                                </FormHelperText>
                            )}
                            <Button
                                fullWidth
                                size="large"
                                sx={{mt: 3}}
                                type="submit"
                                variant="contained"
                            >
                                Einloggen
                            </Button>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    mt: 3,
                                    mb: 3
                                }}
                            >
                                <Link
                                    component={RouterLink}
                                    href={paths.auth.forgotPassword}
                                    underline="hover"
                                    variant="subtitle2"
                                >
                                    Passwort vergessen?
                                </Link>
                            </Box>
                        </form>
                    </CardContent>
                </Card>
            </Layout>
        </>
    );
};

export default Login;

import {BehaviorSubject} from 'rxjs';
import {getFileNameWithDate} from "../utils/utils";
import axios from "axios";
import {PatientDataMapper} from "./PatientDataMapperService";
import toast from "react-hot-toast";


const POLL_INTERVAL_MS = 2000; // 2 seconds
const POLL_DURATION_MS = 60000; // 30 seconds
const STAGGER_DELAY_MS = 500; // Initial delay increases by this amount for each URL

class PdfProcessingService {
    private referrerResultSubject = new BehaviorSubject<any>(null);
    public referrerResult$ = this.referrerResultSubject.asObservable();
    private diagnosisResultSubject = new BehaviorSubject<any>(null);
    public diagnosisResult$ = this.diagnosisResultSubject.asObservable();
    private medicationsAndLabResultSubject = new BehaviorSubject<any>(null);
    public medicationsAndLabResult$ = this.medicationsAndLabResultSubject.asObservable();
    private personalDetailsResultSubject = new BehaviorSubject<any>(null);
    public personalDetailsResult$ = this.personalDetailsResultSubject.asObservable();

    async uploadPdfV2(file: File) {
        const fileName = getFileNameWithDate(file.name);
        const statusTracker = {};

// Show an error toast with a more user-friendly message


        this.referrerResultSubject.next({referrer: PatientDataMapper.generateEmptyReferrer(), isLoading: true});
        this.diagnosisResultSubject.next({diagnosis: PatientDataMapper.generateEmptyDiagnosis(), isLoading: true});
        this.personalDetailsResultSubject.next({
            personalDetails: PatientDataMapper.generateEmptyPatientPersonalDetails(),
            isLoading: true
        });
        this.medicationsAndLabResultSubject.next({
            medicationsAndLab: PatientDataMapper.generateEmptyMedicationAndLabDetails(),
            isLoading: true
        });

        const getUrlResponse = await axios.get<{
            urls: {
                uploadUrl: { putUrl: string; deleteUrl: string };
                retrieveUrls: { key: string; getUrl: string; deleteUrl: string }[]
            }
        }>(`https://hvd782owsl.execute-api.eu-central-2.amazonaws.com/developer/uploads?fileName=${fileName}`);

        let {uploadUrl, retrieveUrls} = getUrlResponse.data.urls;
        await axios.put(uploadUrl.putUrl, file, {headers: {'Content-Type': 'application/pdf'}});

        retrieveUrls = retrieveUrls.filter(url => !url.key.includes('gastro-'));

        retrieveUrls.reverse().forEach(({key, getUrl, deleteUrl}, index) => {
            const initialDelay = STAGGER_DELAY_MS * index;
            console.log(getUrl);
            setTimeout(() => {
                const endPollingTime = Date.now() + POLL_DURATION_MS;
                const pollInterval = setInterval(async () => {
                    if (Date.now() >= endPollingTime) {
                        console.log(`Polling ended for key: ${key}`);
                        this.handleError(key, "Polling ended...");
                        // @ts-ignore
                        statusTracker[key] = true;
                        this.checkAndCleanup(statusTracker, retrieveUrls, uploadUrl, fileName);
                        clearInterval(pollInterval);
                        return;
                    }
                    try {
                        const response = await axios.get(getUrl, {
                            headers: {
                                'Accept': 'application/json', // Erwartet JSON-Daten
                                'Content-Type': 'application/json', // Sendet Daten als JSON
                                'Cache-Control': 'no-cache, no-store, must-revalidate', // Verhindert Caching
                                'Pragma': 'no-cache', // Für ältere HTTP/1.0-Server
                                'Expires': '0' // Setzt das Ablaufdatum in der Vergangenheit
                            }
                        });
                        console.log(`Result for key: ${key}`, response.data);
                        if (response.data) {
                            this.handleData(key, response.data);
                            await axios.delete(deleteUrl); // Delete after successful retrieval and processing
                            console.log(`Deleted: ${key}`);
                            // @ts-ignore
                            statusTracker[key] = true;
                            this.checkAndCleanup(statusTracker, retrieveUrls, uploadUrl, fileName);
                            clearInterval(pollInterval);
                        }
                    } catch (error) {
                        if (axios.isAxiosError(error) && error.response && error.response.status === 404) {
                            console.warn('Data not yet available, will keep polling...');
                        } else {
                            // If it's not a 404, stop polling and show an error
                            clearInterval(pollInterval);
                            this.handleError(key, error);
                            // @ts-ignore
                            statusTracker[key] = true;
                            this.checkAndCleanup(statusTracker, retrieveUrls, uploadUrl, fileName);
                        }
                    }
                }, POLL_INTERVAL_MS);
            }, initialDelay);
        });
    }

    private handleData(key: string, data: any) {
        if (key.includes('doctor')) {
            this.referrerResultSubject.next({referrer: PatientDataMapper.mapReferrer(data), isLoading: false});
        } else if (key.includes('diagnosis')) {
            this.diagnosisResultSubject.next({diagnosis: PatientDataMapper.mapDiagnosis(data), isLoading: false});
        } else if (key.includes('personalDetails')) {
            this.personalDetailsResultSubject.next({
                personalDetails: PatientDataMapper.mapPersonalDetails(data),
                isLoading: false
            });
        } else if (key.includes('medicationAndLab')) {
            this.medicationsAndLabResultSubject.next({
                medicationsAndLab: PatientDataMapper.mapMedicationsAndLab(data),
                isLoading: false
            });
        }
    }

    private handleError(key: string, error: any) {
        if (key.includes('doctor')) {
            this.referrerResultSubject.next({referrer: PatientDataMapper.generateEmptyReferrer(), isLoading: false});
            toast.error('Die Daten für den Zuweiser konnten nicht ausgelesen werden. Möglicherweise ist das PDF zu lange oder der Text des PDFs konnte nicht gelesen werden...', {
                duration: 8000,
                position: "bottom-right"
            });
            console.error('Error fetching referrer data:', error);
        } else if (key.includes('diagnosis')) {
            this.diagnosisResultSubject.next({diagnosis: PatientDataMapper.generateEmptyDiagnosis(), isLoading: false});
            toast.error('Die Daten der Diagnose konnten nicht ausgelesen werden. Möglicherweise ist das PDF zu lange oder der Text des PDFs konnte nicht gelesen werden...', {
                duration: 8000,
                position: "bottom-right"
            });
            console.error('Error fetching referrer data:', error);
        } else if (key.includes('personalDetails')) {
            this.personalDetailsResultSubject.next({
                personalDetails: PatientDataMapper.generateEmptyPatientPersonalDetails(),
                isLoading: false
            });
            toast.error('Die Daten des Patienten konnten nicht ausgelesen werden. Möglicherweise ist das PDF zu lange oder der Text des PDFs konnte nicht gelesen werden...', {
                duration: 8000,
                position: "bottom-right"
            });
            console.error('Error fetching patient data:', error);
        } else if (key.includes('medicationAndLab')) {
            this.medicationsAndLabResultSubject.next({
                medicationsAndLab: PatientDataMapper.generateEmptyMedicationAndLabDetails(),
                isLoading: false
            });
            toast.error('Die Daten der Medikamente und Laborwerte konnten nicht ausgelesen werden. Möglicherweise ist das PDF zu lange oder der Text des PDFs konnte nicht gelesen werden...', {
                duration: 8000,
                position: "bottom-right"
            });
            console.error('Error fetching medication and lab data:', error);
        }
    }

    private checkAndCleanup(statusTracker: any, retrieveUrls: any[], uploadUrl: any, fileName: string) {
        if (Object.keys(statusTracker).length === retrieveUrls.length && Object.values(statusTracker).every(Boolean)) {
            axios.delete(uploadUrl.deleteUrl); // Delete the original PDF
            console.log(`Deleted original PDF: ${fileName}`);
        }
    }


}

export const pdfProcessingService = new PdfProcessingService();

// components/ProtectedRoute.tsx
import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { supabase } from '../db/supabaseClient';

interface ProtectedRouteProps {
    children: JSX.Element;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);

    useEffect(() => {
        const checkSession = async () => {
            const { data: session } = await supabase.auth.getSession();
            setIsAuthenticated(session.session != null);
        };

        checkSession();
    }, []);

    if (isAuthenticated === null) {
        // Während des Auth-Checks können Sie eine Ladeanzeige oder null rendern
        return null; // oder <div>Loading...</div>
    }

    return isAuthenticated ? children : <Navigate to="/login" replace />;
};

export default ProtectedRoute;

import React, {useEffect, useState} from "react";
import {Accordion, AccordionDetails, AccordionSummary, Grid, TextField, Typography} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {pdfProcessingService} from "../../../../../services/PdfProcessingService";
import {PatientDataMapper} from "../../../../../services/PatientDataMapperService";
import {PersonalDetails} from "../../../../../types/types";
import FormAccordion from "../../../../../layouts/form-accordion-layout";
import MultilineTextField from "../../../../../components/MultilineTextField";


const PersonalDetailsForm = () => {
    const [formData, setFormData] = useState<PersonalDetails>(PatientDataMapper.generateEmptyPatientPersonalDetails);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        const subscription = pdfProcessingService.personalDetailsResult$.subscribe(result => {
            if (result) {
                setFormData(result.personalDetails);
                setIsLoading(result.isLoading)
            } else {
                setIsLoading(false)
                const initData = PatientDataMapper.generateEmptyPatientPersonalDetails()
                setFormData(initData)
            }
        });

        // Cleanup-Funktion, wenn die Komponente unmounted
        return () => subscription.unsubscribe();
    }, []);

    const personalDetailsForm = <Grid container spacing={2}>
        {Object.entries(formData).map(([key, {labelName, value}]) => (
            <Grid item xs={12} sm={4} key={key}>
                <MultilineTextField
                    id={"patient-" + key}
                    label={labelName}
                    value={value}
                    onChange={(e) =>
                        setFormData({
                            ...formData,
                            [key]: {
                                labelName: labelName,
                                value: e.target.value,
                            },
                        })
                    }
                />
            </Grid>
        ))}
    </Grid>

    return (
        <Grid item xs={12}>
            <FormAccordion title="Patientendaten" isLoading={isLoading}>
                {personalDetailsForm}
            </FormAccordion>
        </Grid>
    );
};
export default PersonalDetailsForm;
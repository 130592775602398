import { v4 as uuidv4 } from 'uuid';


export function replaceThreeDots(value: any): string {
    // Convert the object to a JSON string if it's not already a string
    const stringValue = typeof value === 'object' ? JSON.stringify(value) : value;

    // Replace all occurrences of three dots, then clean up JSON format artifacts
    return stringValue
        .replace(/\.{3}/g, '') // Remove ellipsis
        .replace(/"\s*([^"]+)\s*"\s*:\s*"/g, '$1: ')  // Clean up and format keys
        .replace(/"\s*,\s*"/g, '", "') // Ensure proper spacing after commas within quotes
        .replace(/"\s*([^"]+)\s*"\s*:\s*/g, '$1: ') // Remove quotes from keys
        .replace(/[{}"[\]]/g, '') // Remove all curly braces, square brackets, and double quotes
        .replace(/,/g, ', '); // Add space after each comma for readability
}

export function getFileNameWithDate(fileName: string): string {
    //const trimmedName = fileName.trim();
    //const dateSuffix = new Date().toISOString();
    const uuidPrefix = uuidv4();
    return `${uuidPrefix}`;
}

// PatientDataMapper.ts
import {
    Diagnosis,
    Medication,
    MedicationsAndLab,
    Patient,
    PatientDataFormState,
    PersonalDetails,
    Referrer
} from "../types/types";
import {replaceThreeDots} from "../utils/utils";
import MedicationAndLabForm from "../pages/index/sections/data-form/sections/medication-and-lab-form";

export class PatientDataMapper {


    static mapPatientData(result: any): PatientDataFormState {
        return {
            patient: {
                personalDetails: {
                    salutation: {
                        labelName: 'Anrede',
                        value: replaceThreeDots(result.Patient.Adressstammdaten.Anrede),
                    },
                    firstName: {
                        labelName: 'Vorname',
                        value: replaceThreeDots(result.Patient.Adressstammdaten.Vorname),
                    },
                    lastName: {
                        labelName: 'Nachname',
                        value: replaceThreeDots(result.Patient.Adressstammdaten.Nachname),
                    },
                    address: {
                        labelName: 'Adresse',
                        value: replaceThreeDots(result.Patient.Adressstammdaten.Adresse)
                    },
                    zipCode: {
                        labelName: 'PLZ',
                        value: replaceThreeDots(result.Patient.Adressstammdaten.PLZ),
                    },
                    city: {
                        labelName: 'Ort',
                        value: replaceThreeDots(result.Patient.Adressstammdaten.Ort),
                    },
                    phoneNumber: {
                        labelName: 'Tel Festnetz',
                        value: replaceThreeDots(result.Patient.Adressstammdaten['Tel Festnetz']),
                    },
                    mobileNumber: {
                        labelName: 'Tel Mobil',
                        value: replaceThreeDots(result.Patient.Adressstammdaten['Tel Mobil']),
                    },
                    dateOfBirth: {
                        labelName: 'Geb. Datum',
                        value: replaceThreeDots((result.Patient.Adressstammdaten['Geb. Datum'] || '')) as string,
                    } as { labelName: string; value: string }, // This asserts the entire object's type
                    healthInsuranceNumber: {
                        labelName: 'Kranken-K',
                        value: replaceThreeDots(result.Patient.Adressstammdaten['Kranken-K']),
                    } as { labelName: string; value: string },
                },
                diagnosis: {
                    acuteDiagnosis: result.Patient.Diagnose["Akut-Diagnose"].map((diagnosis: {
                        Name: string;
                        "ICD-10 Codierung": string;
                    }) => ({
                        name: {
                            labelName: 'Akut-Diagnose',
                            value: replaceThreeDots(diagnosis.Name)
                        },
                        icd10Code: {
                            labelName: 'ICD-10 Codierung',
                            value: replaceThreeDots(diagnosis["ICD-10 Codierung"])
                        },
                    })),
                    chronicDiagnosis: result.Patient.Diagnose["Dauer-Diagnose"].map((diagnosis: {
                        Name: string;
                        "ICD-10 Codierung": string;
                    }) => ({
                        name: {
                            labelName: 'Dauer-Diagnose',
                            value: replaceThreeDots(diagnosis.Name)
                        },
                        icd10Code: {
                            labelName: 'ICD-10 Codierung',
                            value: replaceThreeDots(diagnosis["ICD-10 Codierung"])
                        },
                    })),
                },
                medication: result.Patient.Medikation.map((med: {
                    Medikament: string;
                    Dosierung: string;
                }) => ({
                    medicationName: {
                        labelName: 'Medikament',
                        value: replaceThreeDots(med.Medikament)
                    },
                    dosage: {
                        labelName: 'Dosierung',
                        value: replaceThreeDots(med.Dosierung)
                    },
                })),
                lab: {
                    labResults: {
                        labelName: 'Laborwerte',
                        value: replaceThreeDots(result.Patient.Labor.Laborwerte),
                    },
                },
            },
            referrer: {
                personalDetails: {
                    salutation: {
                        labelName: 'Anrede',
                        value: replaceThreeDots(result.Zuweiser.Adressstammdaten.Anrede),
                    },
                    firstName: {
                        labelName: 'Vorname',
                        value: replaceThreeDots(result.Zuweiser.Adressstammdaten.Vorname),
                    },
                    lastName: {
                        labelName: 'Nachname',
                        value: replaceThreeDots(result.Zuweiser.Adressstammdaten.Nachname),
                    },
                    address: {
                        labelName: 'Adresse',
                        value: replaceThreeDots(result.Zuweiser.Adressstammdaten.Adresse),
                    },
                    zipCode: {
                        labelName: 'PLZ',
                        value: replaceThreeDots(result.Zuweiser.Adressstammdaten.PLZ),
                    },
                    city: {
                        labelName: 'Ort',
                        value: replaceThreeDots(result.Zuweiser.Adressstammdaten.Ort),
                    },
                    phoneNumber: {
                        labelName: 'Tel Festnetz',
                        value: replaceThreeDots(result.Zuweiser.Adressstammdaten['Tel Festnetz']),
                    },
                    mobileNumber: {
                        labelName: 'Tel Mobil',
                        value: replaceThreeDots(result.Zuweiser.Adressstammdaten['Tel Mobil']),
                    },
                    email: {
                        labelName: 'E-Mail',
                        value: replaceThreeDots(result.Zuweiser.Adressstammdaten['E-Mail']),
                    },
                }
            }
        };
    }


    static mapReferrer(result: any) {
        return {
            personalDetails: {
                salutation: {
                    labelName: 'Anrede',
                    value: replaceThreeDots(result.Zuweiser.Adressstammdaten.Anrede),
                },
                firstName: {
                    labelName: 'Vorname',
                    value: replaceThreeDots(result.Zuweiser.Adressstammdaten.Vorname),
                },
                lastName: {
                    labelName: 'Nachname',
                    value: replaceThreeDots(result.Zuweiser.Adressstammdaten.Nachname),
                },
                address: {
                    labelName: 'Adresse',
                    value: replaceThreeDots(result.Zuweiser.Adressstammdaten.Adresse),
                },
                zipCode: {
                    labelName: 'PLZ',
                    value: replaceThreeDots(result.Zuweiser.Adressstammdaten.PLZ),
                },
                city: {
                    labelName: 'Ort',
                    value: replaceThreeDots(result.Zuweiser.Adressstammdaten.Ort),
                },
                phoneNumber: {
                    labelName: 'Tel Festnetz',
                    value: replaceThreeDots(result.Zuweiser.Adressstammdaten['Tel Festnetz']),
                },
                mobileNumber: {
                    labelName: 'Tel Mobil',
                    value: replaceThreeDots(result.Zuweiser.Adressstammdaten['Tel Mobil']),
                },
                email: {
                    labelName: 'E-Mail',
                    value: replaceThreeDots(result.Zuweiser.Adressstammdaten['E-Mail']),
                },
            }
        }
    }

    static mapMedicationsAndLab(result: any): MedicationsAndLab {
        return {
            medication: result.Patient.Medikation.map((med: {
                Medikament: string;
                Dosierung: string;
            }) => ({
                medicationName: {
                    labelName: 'Medikament',
                    value: replaceThreeDots(med.Medikament)
                },
                dosage: {
                    labelName: 'Dosierung',
                    value: replaceThreeDots(med.Dosierung)
                },
            })),
            lab: {
                labResults: {
                    labelName: 'Laborwerte',
                    value: replaceThreeDots(result.Patient.Labor.Laborwerte),
                },
            },
        }
    }

    static mapPersonalDetails(result: any): PersonalDetails {
        return {
            salutation: {
                labelName: 'Anrede',
                value: replaceThreeDots(result.Patient.Adressstammdaten.Anrede),
            },
            firstName: {
                labelName: 'Vorname',
                value: replaceThreeDots(result.Patient.Adressstammdaten.Vorname),
            },
            lastName: {
                labelName: 'Nachname',
                value: replaceThreeDots(result.Patient.Adressstammdaten.Nachname),
            },
            address: {
                labelName: 'Adresse',
                value: replaceThreeDots(result.Patient.Adressstammdaten.Adresse)
            },
            zipCode: {
                labelName: 'PLZ',
                value: replaceThreeDots(result.Patient.Adressstammdaten.PLZ),
            },
            city: {
                labelName: 'Ort',
                value: replaceThreeDots(result.Patient.Adressstammdaten.Ort),
            },
            phoneNumber: {
                labelName: 'Tel Festnetz',
                value: replaceThreeDots(result.Patient.Adressstammdaten['Tel Festnetz']),
            },
            mobileNumber: {
                labelName: 'Tel Mobil',
                value: replaceThreeDots(result.Patient.Adressstammdaten['Tel Mobil']),
            },
            dateOfBirth: {
                labelName: 'Geb. Datum',
                value: replaceThreeDots((result.Patient.Adressstammdaten['Geb. Datum'] || '')) as string,
            } as { labelName: string; value: string }, // This asserts the entire object's type
            healthInsuranceNumber: {
                labelName: 'Kranken-K',
                value: replaceThreeDots(result.Patient.Adressstammdaten['Kranken-K']),
            } as { labelName: string; value: string },
            vekaNumber: {
                labelName: 'VEKA-Nummer',
                value: replaceThreeDots(result.Patient.Adressstammdaten['VEKA-Nummer']),
            } as { labelName: string; value: string },
            email: {
                labelName: 'E-Mail',
                value: replaceThreeDots((result.Patient.Adressstammdaten['E-Mail'] || '')) as string,
            } as { labelName: string; value: string }, // This asserts the entire object's type
        }
    }
    static mapDiagnosis(result: any): Diagnosis {
        return {
            acuteDiagnosis: result.Patient.Diagnose["Akut-Diagnose"].map((diagnosis: {
                Name: string;
                "ICD-10 Codierung": string;
            }) => ({
                name: {
                    labelName: 'Akut-Diagnose',
                    value: replaceThreeDots(diagnosis.Name)
                },
                icd10Code: {
                    labelName: 'ICD-10 Codierung',
                    value: replaceThreeDots(diagnosis["ICD-10 Codierung"])
                },
            })),
            chronicDiagnosis: result.Patient.Diagnose["Dauer-Diagnose"].map((diagnosis: {
                Name: string;
                "ICD-10 Codierung": string;
            }) => ({
                name: {
                    labelName: 'Dauer-Diagnose',
                    value: replaceThreeDots(diagnosis.Name)
                },
                icd10Code: {
                    labelName: 'ICD-10 Codierung',
                    value: replaceThreeDots(diagnosis["ICD-10 Codierung"])
                },
            })),
        }
    }

    static generateEmptyReferrer(): Referrer {
        return {
            personalDetails: {
                salutation: {
                    labelName: 'Anrede',
                    value: ''
                },
                firstName: {
                    labelName: 'Vorname',
                    value: ''
                },
                lastName: {
                    labelName: 'Nachname',
                    value: ''
                },
                address: {
                    labelName: 'Adresse',
                    value: ''
                },
                city: {
                    labelName: 'Ort',
                    value: ''
                },
                zipCode: {
                    labelName: 'PLZ',
                    value: ''
                },
                phoneNumber: {
                    labelName: 'Tel Festnetz',
                    value: ''
                },
                mobileNumber: {
                    labelName: 'Tel Mobil',
                    value: ''
                },
                email: {
                    labelName: 'E-Mail',
                    value: ''
                },
            },
        }
    }

    static generateEmptyPatientPersonalDetails(): PersonalDetails {
        return {
            salutation: {
                labelName: 'Anrede',
                value: ''
            },
            firstName: {
                labelName: 'Vorname',
                value: ''
            },
            lastName: {
                labelName: 'Nachname',
                value: ''
            },
            dateOfBirth: {
                labelName: 'Geb. Datum',
                value: ''
            },
            healthInsuranceNumber: {
                labelName: 'Kranken-K',
                value: ''
            },
            vekaNumber: {
                labelName: 'VEKA-Nummer',
                value: ''
            },
            address: {
                labelName: 'Adresse',
                value: ''
            },
            city: {
                labelName: 'Ort',
                value: ''
            },
            zipCode: {
                labelName: 'PLZ',
                value: ''
            },
            phoneNumber: {
                labelName: 'Tel Festnetz',
                value: ''
            },
            mobileNumber: {
                labelName: 'Tel Mobil',
                value: ''
            },
            email: {
                labelName: 'E-Mail',
                value: ''
            },

        }
    }

    static generateEmptyMedicationAndLabDetails(): MedicationsAndLab {
        return {
            medication: [{
                medicationName: {
                    labelName: 'Medikament',
                    value: ''
                },
                dosage: {
                    labelName: 'Dosierung',
                    value: ''
                },
            }],
            lab: {
                labResults: {
                    labelName: 'Laborwerte',
                    value: ''
                },
            },
        }

    }

    static generateEmptyDiagnosis(): Diagnosis {
        return {
            acuteDiagnosis: [{
                name: {
                    labelName: 'Akut-Diagnose',
                    value: ''
                },
                icd10Code: {
                    labelName: 'ICD-10 Codierung',
                    value: ''
                },
            }],
            chronicDiagnosis: [{
                name: {
                    labelName: 'Dauer-Diagnose',
                    value: ''
                },
                icd10Code: {
                    labelName: 'ICD-10 Codierung',
                    value: ''
                },
            }]
        }
    }

    static generateEmptyPatientForm(): PatientDataFormState {
        return {
            patient: {
                personalDetails: {
                    salutation: {
                        labelName: 'Anrede',
                        value: ''
                    },
                    firstName: {
                        labelName: 'Vorname',
                        value: ''
                    },
                    lastName: {
                        labelName: 'Nachname',
                        value: ''
                    },
                    dateOfBirth: {
                        labelName: 'Geb. Datum',
                        value: ''
                    },
                    healthInsuranceNumber: {
                        labelName: 'Kranken-K',
                        value: ''
                    },
                    address: {
                        labelName: 'Adresse',
                        value: ''
                    },
                    city: {
                        labelName: 'Ort',
                        value: ''
                    },
                    zipCode: {
                        labelName: 'PLZ',
                        value: ''
                    },
                    phoneNumber: {
                        labelName: 'Tel Festnetz',
                        value: ''
                    },
                    mobileNumber: {
                        labelName: 'Tel Mobil',
                        value: ''
                    },
                },
                diagnosis: {
                    acuteDiagnosis: [{
                        name: {
                            labelName: 'Akut-Diagnose',
                            value: ''
                        },
                        icd10Code: {
                            labelName: 'ICD-10 Codierung',
                            value: ''
                        },
                    }],
                    chronicDiagnosis: [{
                        name: {
                            labelName: 'Dauer-Diagnose',
                            value: ''
                        },
                        icd10Code: {
                            labelName: 'ICD-10 Codierung',
                            value: ''
                        },
                    }],
                },
                medication: [{
                    medicationName: {
                        labelName: 'Medikament',
                        value: ''
                    },
                    dosage: {
                        labelName: 'Dosierung',
                        value: ''
                    },
                }],
                lab: {
                    labResults: {
                        labelName: 'Laborwerte',
                        value: ''
                    },
                },
            },
            referrer: {
                personalDetails: {
                    salutation: {
                        labelName: 'Anrede',
                        value: ''
                    },
                    firstName: {
                        labelName: 'Vorname',
                        value: ''
                    },
                    lastName: {
                        labelName: 'Nachname',
                        value: ''
                    },
                    address: {
                        labelName: 'Adresse',
                        value: ''
                    },
                    city: {
                        labelName: 'Ort',
                        value: ''
                    },
                    zipCode: {
                        labelName: 'PLZ',
                        value: ''
                    },
                    phoneNumber: {
                        labelName: 'Tel Festnetz',
                        value: ''
                    },
                    mobileNumber: {
                        labelName: 'Tel Mobil',
                        value: ''
                    },
                },
            },
        };
    }
}

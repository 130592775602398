import React, {useEffect, useState} from 'react';
import {Box, Button, CircularProgress, Typography} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import PdfViewer from "./pdf-viewer";
import {pdfProcessingService} from "../../../../services/PdfProcessingService";

interface PreviewAreaProps {
    file: File;
    previewSrc: string;
    setPreviewSrc: React.Dispatch<React.SetStateAction<string>>;
    setUploadedFile: React.Dispatch<React.SetStateAction<File | null>>;
    isLoading: boolean;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
    setNumPages: React.Dispatch<React.SetStateAction<number | null>>;
}

const PdfFileUpload: React.FC = () => {
    const [uploadedFile, setUploadedFile] = useState<File | null>(null);
    const [previewSrc, setPreviewSrc] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [numPages, setNumPages] = useState<number | null>(null);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files ? event.target.files[0] : null;
        processFile(file);
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        processFile(file);
    };

    const processFile = (file: File | null) => {
        if (file) {
            setUploadedFile(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result as string;

                setPreviewSrc(base64String);

                // Add a hidden input to store the base64 PDF data
                let hiddenInput = document.getElementById('pdf-base64-data') as HTMLInputElement;
                if (!hiddenInput) {
                    hiddenInput = document.createElement('input');
                    hiddenInput.type = 'hidden';
                    hiddenInput.id = 'pdf-base64-data';
                    document.body.appendChild(hiddenInput);
                }
                hiddenInput.value = base64String;
            };
            reader.readAsDataURL(file);
        }
    };


    function handleUploadAndProcessV2() {
        if (uploadedFile && numPages) {
            if (numPages > 3) {
                alert("Das PDF darf maximal 3 Seiten enthalten!");
                setUploadedFile(null);
                setPreviewSrc('');
                setIsLoading(false);
                setNumPages(null);
            } else {
                setIsLoading(true);
                pdfProcessingService.uploadPdfV2(uploadedFile)
                    .then(() => setIsLoading(false))
                    .catch(() => setIsLoading(false)); // Reset loading on error
            }
        }
    }

    useEffect(() => {
        if (uploadedFile) {
            handleUploadAndProcessV2();
        }
    }, [uploadedFile, numPages]);

    const UploadArea: React.FC = () => (
        <Box sx={{
            display: 'flex',
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            position: 'relative' // Adjust position for drop zone layering
        }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '20px',
                    border: '2px dashed #90caf9',
                    backgroundColor: 'rgba(224, 224, 224, 0.3)', // Light grey
                    cursor: 'pointer',
                    '&:hover': {
                        backgroundColor: 'rgba(224, 224, 224, 0.5)',
                    },
                    width: 400,
                    height: 300,
                    marginTop: 8,
                    marginBottom: 8,
                }}
            >
                <CloudUploadIcon sx={{fontSize: '4rem', color: '#90caf9'}}/>
                <Typography variant="h6">Überweisungsbericht hochladen</Typography>
                <input
                    accept="application/pdf"
                    style={{display: 'none'}}
                    id="file-input"
                    type="file"
                    onChange={handleFileChange}
                />
                <label htmlFor="file-input">
                    <Button variant="text" component="span" sx={{mt: 2}}>
                        Datei auswählen
                    </Button>
                </label>
            </Box>
        </Box>
    );

    const PreviewArea: React.FC<PreviewAreaProps> = ({
                                                         file,
                                                         previewSrc,
                                                         setPreviewSrc,
                                                         setUploadedFile,
                                                         isLoading,
                                                         setIsLoading,
                                                         setNumPages
                                                     }) => (
        <Box sx={{
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative' // Ensure this also supports dropping files
        }}>
            <input
                accept="application/pdf"
                style={{display: 'none'}}
                id="raised-button-file"
                type="file"
                onChange={handleFileChange}
            />
            <label htmlFor="raised-button-file" style={{paddingBottom: '22px'}}>
                <Button variant="contained" component="span" startIcon={<CloudUploadIcon/>} sx={{mt: 2, mb: 1}}>
                    Neuen Bericht hochladen
                </Button>
            </label>
            {previewSrc && (
                <>
                    <PdfViewer file={previewSrc} onDocumentLoad={setNumPages}/>
                    <Box sx={{
                        display: isLoading ? 'flex' : 'none',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(255, 255, 255, 0.7)',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 2,
                    }}>
                        <CircularProgress/>
                    </Box>
                </>
            )}
        </Box>
    );

    return (
        <Box sx={{
            display: 'flex',
            //height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            position: 'relative' // Adjust position for drop zone layering
        }}
             onDragOver={handleDragOver}
             onDrop={handleDrop}
        >
            {!uploadedFile ? (
                <UploadArea/>
            ) : (
                <PreviewArea
                    file={uploadedFile}
                    previewSrc={previewSrc}
                    setPreviewSrc={setPreviewSrc}
                    setUploadedFile={setUploadedFile}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    setNumPages={setNumPages}
                />

            )}
        </Box>
    );
};


export default PdfFileUpload;

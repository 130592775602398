import React, {useEffect, useState} from "react";
import {Box, Button, Container, Grid, Tooltip, Typography} from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ReferrerForm from "./sections/referrer-form";
import PersonalDetailsForm from "./sections/personal-details-form";
import DiagnosisForm from "./sections/diagnosis-form";
import MedicationAndLabForm from "./sections/medication-and-lab-form";
import { combineLatest } from 'rxjs';
import {pdfProcessingService} from "../../../../services/PdfProcessingService";


const PatientDataForm = () => {
    const [completePatientDataLoaded, setCompletePatientDataLoaded] = useState<boolean>(true);

    useEffect(() => {
        // // Combine the latest values of each subject into one array
        // const subscription = combineLatest([
        //     pdfProcessingService.referrerResult$,
        //     pdfProcessingService.diagnosisResult$,
        //     pdfProcessingService.medicationsAndLabResult$,
        //     pdfProcessingService.personalDetailsResult$
        // ]).subscribe(([referrerResult, diagnosisResult, medicationsAndLabResult, personalDetailsResult]) => {
        //     // Check if all results are not null
        //     const allResultsAvailable = [referrerResult, diagnosisResult, medicationsAndLabResult, personalDetailsResult].every(result => result !== null);
        //
        //     // If all results are available, set completePatientDataLoaded to true
        //     setCompletePatientDataLoaded(allResultsAvailable);
        // });

        // Clean up the subscription when the component is unmounted
        //return () => subscription.unsubscribe();
    }, []);

    return (
        <Container maxWidth="md">
            <Box display="flex" justifyContent="space-between" alignItems="center" marginTop={2}>
                <Box display="flex" alignItems="center">
                    <img src="/img.png" alt="Shifter Logo" style={{height: '24px', marginRight: '8px'}}/>
                    <Typography variant="h5" sx={{fontWeight: 550}}>
                        Shifter
                    </Typography>
                </Box>
                <Tooltip
                    title={!completePatientDataLoaded ? "Zuerst muss ein Überweisungsbericht hochgeladen werden." : ''}
                    placement="top"
                >
        <span> {/* Tooltip children need to be able to accept ref */}
            <Button
                variant="contained"
                component="span"
                sx={{
                    bgcolor: 'green',
                    '&:hover': {
                        bgcolor: 'darkgreen', // Adjust color as needed
                    },
                    // Ensure the span element is styled as a flex container to prevent stretching
                    display: 'inline-flex',
                }}
                id={"share_button"}
                endIcon={<ArrowForwardIcon/>}
                disabled={!completePatientDataLoaded}
            >
            Daten ins Vitabyte übertragen
          </Button>
        </span>
                </Tooltip>
            </Box>
            <Grid container spacing={2} sx={{marginTop: 2}}>

                <PersonalDetailsForm/>

                <DiagnosisForm/>

                {/*<MedicationAndLabForm/>*/}

                <ReferrerForm/>

            </Grid>
        </Container>
    );
};
export default PatientDataForm;
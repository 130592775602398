import React, {useState} from 'react';
import {Document, Page, pdfjs} from 'react-pdf';
import {IconButton} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {useWidth} from "../../../../context/width-context";

interface PdfViewerProps {
    file: string;
    onDocumentLoad: (numPages: number) => void;
}

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfViewer: React.FC<PdfViewerProps> = ({file, onDocumentLoad}) => {

    const width = useWidth(); // This is the width of the Grid item from the parent


    const [numPages, setNumPages] = useState<number | null>(null);
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({numPages}: { numPages: number }) {
        onDocumentLoad(numPages);
        setNumPages(numPages);
    }

    function goToPrevPage() {
        setPageNumber((prevPageNumber) => Math.max(prevPageNumber - 1, 1));
    }

    function goToNextPage() {
        setPageNumber((prevPageNumber) => Math.min(prevPageNumber + 1, numPages ?? 0));
    }

    const pageWidth = Math.min(width ? width * 0.8 : 200, 800)


    // @ts-ignore
    return (
        <div style={{width: '100%'}}>
            <IconButton
                onClick={goToPrevPage}
                disabled={pageNumber <= 1}
                sx={{
                    position: 'absolute',
                    left: 0,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    zIndex: 1
                }}
            >
                <ArrowBackIosIcon/>
            </IconButton>
            <Document
                file={file}
                onLoadSuccess={onDocumentLoadSuccess}
            >
                <Page
                    pageNumber={pageNumber}
                    width={pageWidth} // Set the width dynamically based on the parent's width
                    renderMode="canvas"
                    renderTextLayer={false}
                    renderAnnotationLayer={false}
                />
            </Document>
            <IconButton
                onClick={goToNextPage}
                disabled={pageNumber >= (numPages ?? 0)}
                sx={{
                    position: 'absolute',
                    right: 0,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    zIndex: 1
                }}
            >
                <ArrowForwardIosIcon/>
            </IconButton>
        </div>
    );
};

export default PdfViewer;

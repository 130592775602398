import { useFormik } from 'formik';
import * as Yup from 'yup';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import {supabase} from "../../db/supabaseClient";
import {useMounted} from "../../hooks/useMounted";
import {useRouter} from "../../hooks/useRouter";
import {paths} from "../../paths";
import {Layout} from "../../layouts/auth-layout";

interface Values {
    email: string;
    submit: null;
}

const initialValues: Values = {
    email: '',
    submit: null
};

const validationSchema = Yup.object({
    email: Yup
        .string()
        .email('Es Muss eine gültige E-Mailadresse sein')
        .max(255)
        .required('Die E-Mailadresse wird benötigt')
});

const ForgotPassword = () => {
    const isMounted = useMounted();
    const router = useRouter();
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, helpers): Promise<void> => {
            try {

                console.log(values.email);
                // send forgot password mail in suppabase
                const {error} = await supabase.auth.resetPasswordForEmail(values.email);


                await supabase.auth.resetPasswordForEmail(values.email, {
                    redirectTo: paths.auth.resetPassword,
                })


                if (error) throw error;

                if (isMounted()) {
                    const searchParams = new URLSearchParams({ username: values.email }).toString();
                    const href = paths.auth.resetPassword + `?${searchParams}`;
                    router.push(href);
                }
            } catch (err) {
                console.error(err);

                if (isMounted()) {
                    helpers.setStatus({ success: false });
                    // @ts-ignore
                    helpers.setErrors({ submit: err.message });
                    helpers.setSubmitting(false);
                }
            }
        }
    });

    //usePageView();

    return (
        <>
            <Layout>
                <Card elevation={16}>
                    <CardHeader
                        sx={{ pb: 0 }}
                        title="Passwort vergessen?"
                    />
                    <CardContent>
                        <form
                            noValidate
                            onSubmit={formik.handleSubmit}
                        >
                            <TextField
                                autoFocus
                                error={!!(formik.touched.email && formik.errors.email)}
                                fullWidth
                                helperText={formik.touched.email && formik.errors.email}
                                label="Email Address"
                                name="email"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                type="email"
                                value={formik.values.email}
                            />
                            {formik.errors.submit && (
                                <FormHelperText
                                    error
                                    sx={{ mt: 3 }}
                                >
                                    {formik.errors.submit as string}
                                </FormHelperText>
                            )}
                            <Button
                                disabled={formik.isSubmitting}
                                fullWidth
                                size="large"
                                sx={{ mt: 3 }}
                                type="submit"
                                variant="contained"
                            >
                                Passwort zurücksetzen
                            </Button>
                        </form>
                    </CardContent>
                </Card>
            </Layout>
        </>
    );
};

export default ForgotPassword;
